import React, { useState } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import axios from 'axios';
import './scss/style.scss';

const {REACT_APP_ENV} = process.env;
const apiUrl = (REACT_APP_ENV !== 'DEV')?"https://api.hcp.psales.pt":"http://localhost:5012";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

axios.interceptors.request.use(
  config => {
    const { origin } = new URL(config.url);
    const allowedOrigins = [apiUrl];
    const token = localStorage.getItem('token');
    if (allowedOrigins.includes(origin)) {
      config.headers.authorization = `${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

function App() {
  const storedJwt = localStorage.getItem('token');
  const [jwt, setJwt] = useState(storedJwt || null);
  const [email, setEmail] = useState();
  const [password, setPasssword] = useState();

  const getJwt = async () => {
    const { data } = await axios.post(`${apiUrl}/login`, {
      username: email,
      password: password
    });
    // VALIDATE RESPONSE
    if( data.status === 'OK'){
      localStorage.setItem('token', data.token);
      localStorage.setItem('role', data.user.role);
      setJwt(data.token);
    } else{
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      setJwt(null);
      alert("ERROR LOGING IN!");
    }
  };

  if(!jwt || jwt.length<50){
    return (
      <div className="c-app c-default-layout flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="6">
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <CForm>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <CInputGroup className="mb-3">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-user" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput type="text" placeholder="Username" autoComplete="username"
                          onChange={ e => {
                            setEmail(e.target.value);
                          }} />
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-lock-locked" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput type="password" placeholder="Password" autoComplete="current-password"
                          onChange={ e => {
                            setPasssword(e.target.value);
                          }} />
                      </CInputGroup>
                      <CRow>
                        <CCol xs="6">
                          <CButton color="primary" className="px-4"
                            onClick={() => {
                              getJwt();
                            }}
                          >Login</CButton>
                        </CCol>
                        <CCol xs="6" className="text-right">
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    )
  }


  return (
    <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
            <Route path="/" name="Home" render={props => <TheLayout {...props} onChange={(value)=>{ setJwt(value )}}/>} />
          </Switch>
        </React.Suspense>
    </HashRouter>
  );

}

export default App;
